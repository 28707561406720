import React from 'react';
import Footer from './Footer';

export function Home() {
 
  return (
    <div >
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Kolandium - Work in progress!
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Test 2023 Thanksgiving Anim aute id magna aliqua ad ad non deserunt
          sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat
          veniam occaecat fugiat aliqa.
        </p>
   
      </div>
      
     <div>
      <div class="mt-40 bottom-1 "><Footer companyName="Your Company" year={new Date().getFullYear()} /></div>
   </div>
    </div>
    
    
  );
}
