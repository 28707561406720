import React, { useState, useEffect } from 'react';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import Preloader from '../Preloader';

const SelectUploaderPassForm = ({ onPassSelectionChange, navigateToTab }) => {
  const [selectedPass, setSelectedPass] = useState('');
  const [totalPasses, setTotalPasses] = useState(null);

  const passes = [
    { id: 'regular', name: '1. Regular Uploader Pass', description: 'Allows 2 Submissions for the current running session of Top Live for the specific channel' },
    { id: 'premium', name: '2. Premium Uploader Pass', description: 'Allows 5 Submissions for the current running session of Top Live for the specific channel' },
  ];


  const { data: passesConfig, isLoading, isError } = useQuery('passesConfig', async () => {
    const data1 = await Auth.currentSession();
    const idToken = data1.getIdToken().getJwtToken();

    const response = await fetch('https://api.kolandium.com/get_passes_config', {
      headers: { 'Authorization': `Bearer ${idToken}` }
    });

    const data = await response.json();
    return data;
  });

  useEffect(() => {
    if (passesConfig) {
      setTotalPasses(passesConfig.total_passes);
      console.log(`Total Passes: ${passesConfig.total_passes}`);
    }
  }, [passesConfig]);


  useEffect(() => {
    const storedPass = localStorage.getItem('selectedPass');
    if (storedPass) {
      setSelectedPass(storedPass);
      onPassSelectionChange(true);
    }
  }, [onPassSelectionChange]);

  const handlePassSelection = (passId) => {
    setSelectedPass(passId);
    localStorage.setItem('selectedPass', passId);
    onPassSelectionChange(true);
    console.log(`Selected Pass: ${passId}`);
  };

  const handleCancel = () => {
    setSelectedPass('');
    localStorage.removeItem('selectedPass');
    onPassSelectionChange(false);
    console.log('Selection canceled');
  };

  const handleSelectUploaderPassNext = () => {
    if (selectedPass) {
      navigateToTab('Payment and Registration');
      console.log(`Navigating to: Payment and Registration with selected pass: ${selectedPass}`);
    }
  };

  if (isLoading) return <div><Preloader/></div>;
  if (isError) return <div>Error...</div>

  return (
    <div className="flex justify-center items-center">
      <div className="text-center rounded-lg w-96">
        <div className="mt-2 border-b border-gray-200 bg-white sm:px-6">
          <button
            className="bg-white-500 hover:border-b-4 hover:border-green-500 hover:text-black text-black font-bold py-2 px-4 border border-black"
            onClick={() => alert('Select Uploader Pass')}
          >
            Select Uploader Pass{' '}
            {selectedPass && (
              <span className="text-green-500">✓</span>
            )}
          </button>
          <div className="-ml-4 mt-2 flex flex-wrap items-center sm:flex-nowrap">
            <div className="ml-4 mt-2"></div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <h3 className="font-semibold text-gray-900">Please Select Your Uploader Pass</h3>
              <button
                type="button"
                className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Passes Still Available for current session: {totalPasses !== null ? totalPasses : 'Loading...'}
              </button>
            </div>
          </div>
          <div>
            <fieldset className="mt-2">
              <legend className="sr-only">Uploader pass</legend>
              <div className="divide-y divide-gray-200">
                {passes.map((pass) => (
                  <div key={pass.id} className="relative flex items-start pb-4 pt-3.5">
                    <div className="min-w-0 flex-1 text-sm leading-6">
                      <label htmlFor={`pass-${pass.id}`} className="font-medium text-gray-900">
                        {pass.name}
                      </label>
                      <p id={`pass-${pass.id}-description`} className="text-gray-500">
                        {pass.description}
                      </p>
                    </div>
                    <div className="ml-3 flex h-6 items-center">
                      <input
                        id={`pass-${pass.id}`}
                        aria-describedby={`pass-${pass.id}-description`}
                        name="passes"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        onChange={() => handlePassSelection(pass.id)}
                        checked={selectedPass === pass.id}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          <div className="flex justify-center mb-5">
            <button
              type="button"
              className="mr-4 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 flex items-center"
              onClick={() => navigateToTab('Basic Info')}
            >
              <span className="ml-1">
                <ArrowLeftIcon className="h-5 w-5 text-white font-bold" aria-hidden="true" />
              </span>
              Previous
            </button>
            <button
              type="button"
              className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 flex items-center"
              onClick={handleCancel}
            >
              Cancel
              <span className="ml-1">
                <XMarkIcon className="h-5 w-5 text-white font-bold" aria-hidden="true" />
              </span>
            </button>
            <button
              type="button"
              className={`ml-5 rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm flex items-center ${selectedPass ? 'bg-green-600 hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600' : 'bg-gray-300 cursor-not-allowed'}`}
              disabled={!selectedPass}
              onClick={handleSelectUploaderPassNext}
            >
              Next
              <span className="ml-1">
                <ArrowRightIcon className="h-5 w-5 text-white font-bold" aria-hidden="true" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectUploaderPassForm;
