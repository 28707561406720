import React from 'react';
import API from "../../config/api";
import { useQuery } from 'react-query';
import { Auth } from 'aws-amplify';
import Vimeo from '@u-wave/react-vimeo';
import Preloader from "./Preloader";
import { SlCalender } from "react-icons/sl";
import { FaEye } from "react-icons/fa";


// Fetch video list
const fetchVideoList = async () => {
  try {
    const data = await Auth.currentSession();
    const idToken = data.getIdToken().getJwtToken();
    const res = await API.get("/top40_videos", {
      headers: {
        Authorization: idToken,
      },
    });
    console.log('Fetched video list:', res.data["video_list"]);
    return res.data["video_list"];
  } catch (error) {
    console.error('Error fetching video list:', error);
    throw error;
  }
};

// Format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Convert number to word
const convertNumberToWord = (number) => {
  switch (number) {
    case 0:
      return "ONE"; // Returns a string
    default:
      return (number + 1).toString();
  }
};

const TheTopLiveContent = () => {
  const { data: videoList, error, isLoading } = useQuery(['videoList'], fetchVideoList);

  if (isLoading) return <div><Preloader/></div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container mt-5 grid grid-cols-1 gap-4">
      {videoList.length > 0 ? (
        <div className="mt-5 grid grid-cols-1 gap-4">
          {videoList.slice(0, 40).map((video, index) => (
            <div key={video.video_id} className="flex flex-col sm:flex-row">
              <div className="mt-5 flex-shrink-0 w-full sm:w-14 h-20 sm:h-auto bg-gray-800 rounded-md mb-4 sm:mb-0 sm:mr-4 flex justify-center items-center">
                {index === 0 ? (
                  <div className="text-white text-1xl font-bold text-2xl mb-2 flex flex-row md:flex-col">
                    {convertNumberToWord(index).split("").map((char, charIndex) => (
                      <div key={charIndex}>{char}</div>
                    ))}
                  </div>
                ) : (
                  <span className="text-white text-2xl font-bold">{index + 1}</span>
                )}
              </div>

              <div className="mr-4 mt-4 flex flex-col border border-inherit rounded-lg lg:flex-row lg:items-start">
                <div className="mr-4 ml-2 mb-2 mt-4 flex flex-col rounded-lg lg:flex-row lg:items-start transition-transform transform hover:scale-105">
                  <div className={`${index === 0 ? 'w-full aspect-w-21 aspect-h-9 lg:w-1/2' : 'w-full sm:w-[450px] h-[250px]'}`}>
                    {!video.error ? (
                      <Vimeo
                        tabIndex="-1"
                        className={`${index === 0 ? 'w-full h-full' : 'w-full sm:w-[450px] h-[250px] mb-2'}`}
                        controlsList="nodownload"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        width={`${index === 0 ? '640' : '450'}`}
                        height={`${index === 0 ? '360' : '250'}`}
                        video={video.link}
                      />
                    ) : (
                      <div className="mr-4 ml-2 mt-4 flex flex-col rounded-lg lg:flex-row lg:items-start">
                        <div className="mb-4 w-full aspect-w-21 aspect-h-9 lg:w-1/2">
                          <iframe
                            src={video.link}
                            className="w-full h-auto sm:h-[250px] lg:h-full lg:w-full rounded-lg"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            title="not_available"
                          ></iframe>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="px-4 sm:px-0">
                  <h3 className="mt-4 ml-2 max-w-2xl text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl lg:col-span-2 xl:col-auto">
                    {video.title}
                  </h3>
                  {index === 0 ? (
                    <p className="ml-2 mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                      {video.description ? video.description : "No comment available"}
                    </p>
                  ) : null}

                  <div className="mt-4 lg:mt-2 flex lg:justify-end lg:items-center">
                    <div className="flex lg:text-left items-center text-sm text-gray-400 text-2xl rounded-md">
                      <SlCalender className="mr-2" />
                      <p className="whitespace-nowrap">{formatDate(video.created_time)}</p>
                    </div>
                  </div>
                </div>
                <table className="pl-6 border-none mr-4 lg:mt-6 lg:ml-6">
                <thead>
                  <tr>
                    <th className="border-none ml-2">Views</th>
                    <th className="border-none">
                      Rating <span className="text-2xl ml-2">{video.rating}</span>
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-none ml-2">
                      <div className="flex justify-center items-center">
                        <span className="flex items-center text-sm md:text-base lg:text-lg">
                          <FaEye className="text-xl mr-1" /> {video.views}
                        </span>
                      </div>
                    </td>
                    <td className="border-none ml-2">
                      <div className="flex items-center ml-2">
                        {[...Array(5)].map((_, i) => (
                          <svg
                            key={i}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill={i < video.rating ? "currentColor" : "none"}
                            stroke="currentColor"
                            className={`w-6 h-6 ${i < video.rating ? "text-yellow-500" : "text-gray-300"}`}
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ))}
                      </div>
                    </td>
                    <td>
                     
                    </td>
                  </tr>
                </tbody>
                {index === 0 ? (
                  <thead>
                  
                  </thead>
                ) : null}
                {index === 0 ? (
                  <tbody className="mt-30">
                    <tr className="mt-6">
                      <td className="border-none custom-padding pt-6 flex justify-center items-center">
                      
                      </td>

                      <td className="border-none custom-padding pt-6">
                     
                      </td>
                    </tr>
                  </tbody>
                ) : null}
              </table>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No videos found</div>
      )}
    </div>
  );
};

export default TheTopLiveContent;
