import React from "react";
import API from "../../config/api";
import { Auth } from "aws-amplify";
import Vimeo from '@u-wave/react-vimeo';
import { FaEye } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";

import { useQuery } from "react-query";

const PublishedVideos = () => {
  const { data: videoList, isLoading, error } = useQuery("videoList", fetchVideoList);

  async function fetchVideoList() {
    try {
      const data = await Auth.currentSession();
      const idToken = data.getIdToken().getJwtToken();
      const res = await API.get(`/published_videos`, {
        headers: {
          Authorization: idToken,
        },
      });

      console.log("VideoListApiTest.jsx: fetchVideoList() - res.data: ", res.data);
      return res.data['video_list'];
    } catch (error) {
      throw new Error(error);
    }
  }
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-start h-screen mt-0">
        <img className="max-w-xs mt-4 justify-center" src="./preloader-unscreen.gif" alt="Loading" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 overflow-hidden">
      {videoList.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {videoList.map((video, index) => (
            <div
              key={video.link}
              className="bg-white p-4 rounded-lg shadow-md overflow-hidden flex flex-col transition-transform transform hover:scale-105"
            >
              <div className="flex-grow flex items-center justify-center mb-4 rounded-lg">
                <div className="rounded-lg">
                  <Vimeo
                    video={video.link}
                    width="520"
                    height="200"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-xl font-semibold text-left">{video.title}</h3>
                <div className="flex items-center">
                  <h1 className="font-bold mr-2">uploaded by: {video.channel_name}</h1>
                </div>
              </div>
              <div className="flex items-center mb-4">
                {[...Array(5)].map((_, starIndex) => (
                  <svg
                    key={starIndex}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill={starIndex < video.rating ? "currentColor" : "none"}
                    stroke="currentColor"
                    className={`w-6 h-6 ${starIndex < video.rating ? "text-yellow-500" : "text-gray-300"}`}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ))}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center text-sm">
                  <SlCalender className="mr-2" />
                  <p>{formatDate(video.created_time)}</p>
                </div>
                <div className="flex items-center text-sm">
                  <FaEye className="mr-2" />
                  <p>{video.views} views</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No videos available</p>
      )}
    </div>
  );
};

export default PublishedVideos;
