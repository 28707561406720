import React from 'react';

const Preloader = () => {
  return (
    <div className="flex justify-center items-start h-screen mt-0 ">
      <img className="max-w-xs mt-4  justify-center " src="./preloader-unscreen.gif" alt="Loading" />
    </div>
  );
};

export default Preloader;
