/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
let awsmobile = {};
switch (process.env.NODE_ENV) {
  case "development":
    awsmobile = {
      aws_project_region: "us-east-1",
      aws_cognito_identity_pool_id:
        "us-east-1:ea2bf63e-be67-45c9-ab9c-98dfc8df7318",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_8FkzQLHVv",
      aws_user_pools_web_client_id: "10ds14bet0v09l4md17jv29n1p",
      oauth: {
        domain: "kolandium.auth.us-east-1.amazoncognito.com",
        scope: ["email", "openid", "phone", "profile"],
        redirectSignIn: "http://localhost:3000",
        redirectSignOut: "http://localhost:3000/logout",
        responseType: "code",
      },
      federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
      aws_cognito_username_attributes: ["EMAIL"],
      aws_cognito_social_providers: [],
      aws_cognito_signup_attributes: ["EMAIL", "PHONE"],
      aws_cognito_mfa_configuration: "OFF",
      aws_cognito_mfa_types: [],
      aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
          "REQUIRES_LOWERCASE",
          "REQUIRES_UPPERCASE",
          "REQUIRES_NUMBERS",
          "REQUIRES_SYMBOLS",
        ],
      },
      aws_cognito_verification_mechanisms: ["EMAIL"],
    };
    break;
  default:
    awsmobile = {
      aws_project_region: "us-east-1",
      aws_cognito_identity_pool_id:
        "us-east-1:ea2bf63e-be67-45c9-ab9c-98dfc8df7318",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_8FkzQLHVv",
      aws_user_pools_web_client_id: "10ds14bet0v09l4md17jv29n1p",
      oauth: {
        domain: "kolandium.auth.us-east-1.amazoncognito.com",
        scope: ["email", "openid", "phone", "profile"],
        redirectSignIn: "https://kolandium.com",
        redirectSignOut: "https://kolandium.com/logout",
        responseType: "code",
      },
      federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
      aws_cognito_username_attributes: ["EMAIL"],
      aws_cognito_social_providers: [],
      aws_cognito_signup_attributes: ["EMAIL"],
      aws_cognito_mfa_configuration: "OFF",
      aws_cognito_mfa_types: [],
      aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
          "REQUIRES_LOWERCASE",
          "REQUIRES_UPPERCASE",
          "REQUIRES_NUMBERS",
          "REQUIRES_SYMBOLS",
        ],
      },
      aws_cognito_verification_mechanisms: ["EMAIL"],
    };
    break;
}

export default awsmobile;
