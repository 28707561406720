import {
  Authenticator,
  Button,
  Heading,
  Text,
  useTheme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useAuthenticator, View } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          {/* todo <Image alt="Amplify logo" src={logo} width="75px" /> */}
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center" >
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              color={"white"}
              border={"none"}
              marginBottom={'medium'}
              variation="link"
              
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
              color={"white"}
              marginBottom={'medium'}
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading> 
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email",
        order: 1,
      },
    },
    signUp: {
      email: {
        placeholder: "Enter your email:",
        order: 1,
      },
      password: {
        label: "Password:",
        placeholder: "Enter your Password:",
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: "Confirm Password:",
        order: 3,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Enter your Password:",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Enter your email:",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Enter your Confirmation Code:",
        label: "New Label",
        isRequired: false,
      },
      confirm_password: {
        placeholder: "Enter your Password Please:",
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "test issuer",
        totpUsername: "amplify_qr_test_user",
      },
      confirmation_code: {
        label: "New Label",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "New Label",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
  };

  return (
    <View className="auth-wrapper">
      <Authenticator
        formFields={formFields}
        components={components}
      ></Authenticator>
    </View>
  );
}
