/* eslint-disable */
import MainHeader from './MainHeader';
import UserProfileDisplay from './UserProfileDisplay';

export default function UserHomeHeader() {
  return (
    <>
      <MainHeader/>
      <div className="mt-10">
        <UserProfileDisplay />
      </div>
    </>
  );
}
