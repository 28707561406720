import React, { useState } from 'react';
import PaymentandRegistrationForm from './PaymentandRegistrationForm';
import BasicInfoForm from './BasicInfoForm';
import SelectUploaderPassForm from './SelectUploaderPassForm';

export default function DisplayForm() {
  const [activeTab, setActiveTab] = useState('Basic Info');
  const [isBasicInfoFilled, setIsBasicInfoFilled] = useState(false);
  const [selectedPass, setSelectedPass] = useState(false);
  const [channelExists, setChannelExists] = useState(false);

  const tabs = [
    {
      name: 'Basic Info',
      href: '#',
      current: activeTab === 'Basic Info',
    },
    {
      name: 'Select Uploader Pass',
      href: '#',
      current: activeTab === 'Select Uploader Pass',
      disabled: !isBasicInfoFilled || !channelExists,
    
    },
    {
      name: 'Payment and Registration',
      href: '#',
      current: activeTab === 'Payment and Registration',
      disabled: !isBasicInfoFilled || !channelExists||!selectedPass,
    },
  ];

  const handleTabClick = (tabName) => {
    const tab = tabs.find(tab => tab.name === tabName);
    if (tab && !tab.disabled) {
      setActiveTab(tabName);
    }
  };

  const handlePassSelectionChange = (hasSelectedPass) => {
    setSelectedPass(hasSelectedPass);
  };

  const navigateToTab = (tabName) => {
    setActiveTab(tabName);
  };

  console.log('Active Tab:', activeTab);
  console.log('Is Basic Info Filled:', isBasicInfoFilled);
  console.log('Selected Pass:', selectedPass);

  return (
    <div>
      <div className="mx-auto max-w-2xl text-center">
        <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-l">Become an Uploader</h3>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Being an Uploader Allows Users to Upload Content and a chance to participate in the Top-Live.
          In order to become an uploader, it is essential to create a channel name, get an uploader pass, and register.
          The Uploader Pass will only be eligible for the current running session of Top Live.
        </p>
      </div>

      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">Select a tab</label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={activeTab}
          onChange={(e) => handleTabClick(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name} disabled={tab.disabled}>{tab.name}</option>
          ))}
        </select>
      </div>

      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              href={tab.href}
              className={`${
                tab.disabled ? 'text-gray-500 cursor-not-allowed' : activeTab === tab.name ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'
              } ${
                tabIdx === 0 ? 'rounded-l-lg' : ''
              } ${
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : ''
              } group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10`}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => !tab.disabled && handleTabClick(tab.name)}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={`${
                  activeTab === tab.name ? 'bg-green-500' : 'bg-transparent'
                } absolute inset-x-0 bottom-0 h-0.5`}
              />
            </a>
          ))}
        </nav>
      </div>

      {activeTab === 'Basic Info' && <BasicInfoForm 
        setIsBasicInfoFilled={setIsBasicInfoFilled}
        navigateToTab={navigateToTab} />}
      {activeTab === 'Select Uploader Pass' && isBasicInfoFilled && (
        <SelectUploaderPassForm
          onPassSelectionChange={handlePassSelectionChange}
          navigateToTab={navigateToTab}
        />
      )}
      {activeTab === 'Payment and Registration' && isBasicInfoFilled &&selectedPass&&(
        <PaymentandRegistrationForm
          setChannelExists={setChannelExists}
          updateFields={() => {}}
          navigateToTab={navigateToTab}
        />
      )}
    </div>
  );
}
