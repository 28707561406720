import { ChangeEvent, useCallback, useState, useRef } from 'react';
import * as tus from 'tus-js-client';
import { Auth } from "aws-amplify";
import API from "../../config/api";
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useQuery } from 'react-query';

/**
 * Retrieves the upload link for a video of the specified size.
 * @param size - The size of the video in bytes.
 * @returns A Promise that resolves to the upload link.
 */
async function getUploadLink(size: number | any, title: string): Promise<any> {
  try {
    size = size || 0;
    const data = await Auth.currentSession();
    const idToken = data.getIdToken().getJwtToken();
    const response = await API.get(`/upload_link?size=${size}&title=${title}`, {
      headers: {
        Authorization: idToken,
      }
    });
    const res = response.data;
    console.log("Response from API: " + res);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function useUserProfile() {
  return useQuery('userProfile', async () => {
    const data = await Auth.currentSession();
    const idToken = data.getIdToken().getJwtToken();
    console.log('idToken:', idToken);
    try {
      const response = await fetch('https://api.kolandium.com/user_profile', {
        headers: {
          Authorization: idToken,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user profile');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  });
}

const VideoUploader = () => {
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState<string>('');
  const [error, setError] = useState<string | null>(null); // State for error message
  const [progress, setProgress] = useState<string>('');
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { data: profile, isLoading, isError } = useUserProfile(); // Fetch user profile data
  

  const handleFileSelect = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.item(0);
    if (selectedFile) {
      setFile(selectedFile);
      setIsUploadComplete(false); // Reset the finish button visibility
      setError(null); // Clear any previous errors
    }
  }, []);

  const handleTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setError(null); // Clear any previous errors
  }, []);

  const handleUploadStart = useCallback(async () => {
    if (!file) {
      console.log("No file selected");
      setError("Please select a file to upload."); // Set error message
      return;
    }
    
    if (!title) {
      console.log("No title entered");
      setError("Please enter a title for the video."); // Set error message
      return;
    }

    if (title.length > 30) {
      console.log("Title is too long");
      setError("Title is too long. Please enter a title with 30 characters or less."); // Set error message
      return;
    }

    setProgress(`PROCESSING FILE: ${file.name} (${file.size} BYTES), PLEASE WAIT.`);
    
    try {
      const response = await getUploadLink(file.size, title);
      if (!response || !response.upload || !response.upload.upload_link) {
        setProgress("FAILED TO GET UPLOAD LINK. PLEASE TRY AGAIN. Refresh the page.");
        return;
      }
      
      setProgress("PREPARING TO UPLOAD FILE");

      const upload = new tus.Upload(file, {
        uploadUrl: response.upload.upload_link,
        endpoint: response.upload.upload_link,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError(error) {
          console.error(`Failed because: ${error}`);
          setProgress("FAILED TO UPLOAD FILE. CHECK LOGS, REFRESH, AND TRY AGAIN.");
        },
        onProgress(bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(bytesUploaded, bytesTotal, `${percentage}%`);
          setProgress(`${percentage}%`);
          setUploadPercentage(parseFloat(percentage));
        },
        onSuccess() {
          console.log('Download from %s', upload.url);
          setProgress("UPLOAD COMPLETE. READY FOR ANOTHER UPLOAD!");
          setUploadPercentage(100);
          setFile(null);
          setIsUploadComplete(true); // Show the finish button when upload is complete
        },
      });
      upload.start();
    } catch (error) {
      console.error('Upload failed:', error);
      setProgress("FAILED TO UPLOAD FILE. CHECK LOGS, REFRESH, AND TRY AGAIN.");
    }
  }, [file, title]);
  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching user profile</div>;
  return (
    <div>
      {profile && profile.remaining_videos === 0 ? (
      <div className="flex items-center justify-center">
      <div className="p-6 bg-gradient-to-r from-red-200 via-red-100 to-red-200 border border-red-300 rounded-lg shadow-md max-w-md mx-auto">
        <div className="flex items-center justify-center">
          
          <svg
            className="w-10 h-10 text-red-700"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 001 1h3a1 1 0 100-2h-2V6z"
              clipRule="evenodd"
            />
          </svg>
          <h4 className="ml-3 text-xl font-bold text-red-800">Sorry...!</h4>
        </div>
        <p className="mt-3 text-md text-red-700 text-center">
          You have no remaining video uploads...
        </p>
      </div>
    </div>
    
     
      ) : (
        <>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />

          <div className="flex flex-col items-center w-full sm:max-w-xs mx-auto">
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter video title"
              className="w-full px-4 py-2 border rounded-md text-sm focus:ring-green-600 focus:border-green-600 mb-2"
            />
            {error && <p className="text-red-600 text-sm mb-2">{error}</p>} {/* Display error message here */}
            <button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="mt-4 inline-flex items-center gap-x-1.5 rounded-md bg-green-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-800"
              disabled={!!file}
            >
              <CheckCircleIcon className="-ml-0.5 h-5 w-5 bg-green-800" aria-hidden="true" />
              {file ? `Selected: ${file.name}` : "Select file to upload"}
            </button>
          </div>

          {file && (
            <button
              type="button"
              onClick={handleUploadStart}
              className="mt-4 relative ml-5 inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Confirm upload
              {uploadPercentage > 0 && (
                <div className="absolute bottom-0 left-0 h-1.5 bg-green-800" style={{ width: `${uploadPercentage}%` }} />
              )}
            </button>
          )}

          {isUploadComplete && (
            <button
              type="button"
              onClick={() => {
                console.log('Finish button clicked');
                window.location.href = "/user-profile-display";
              }}
              className="mt-4 relative ml-5 inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              <span className="mr-1">Finish</span>
            </button>
          )}

          <h6 className="uppercase font-bold">{progress}</h6>
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="mb-3 mt-5 text-lg leading-8 text-black-600">
              After the upload is completed, the video will be submitted and considered
              for the Top Live. The submission will be counted against remaining
              uploads in the uploader pass. Also, the video and details can be viewed
              on the channel page.
            </h1>
            <b>Note:</b>
            <ul className="mx-auto max-w-2xl text-center max-w-md space-y-1 text-black-500 list-disc list-inside dark:text-gray-400">
              <li>Video length limit is 15 mins</li>
              <li>Video bitrate limit is X MBPs</li>
              <li>Once submitted, terms and conditions will apply to the uploaded content.</li>
              <li>Please avoid any violations of copyrights and privacy rights, and do not post any stolen content.</li>
              <li>It is not recommended to upload the same content multiple times, as it can negatively impact your performance in the Top Live.</li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default VideoUploader;
