import MainHeader from '../MainHeader';
import DisplayForm from './DisplayForm';
export default function BecomeUploaderHeader() {
return (
    <>
    
    <MainHeader/>
      <div className="mt-10">
       <DisplayForm/>
      </div>
    </>
  );
}
