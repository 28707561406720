import MainHeader from './MainHeader';
import VideoUploader from './VideoUploader';

export default function HomeHeader() {
 
  return (
    <>
    <MainHeader/>
      <div className="mt-10">
        <VideoUploader />
      </div>
    </>
  );
}
