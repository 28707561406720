/* eslint-disable */
import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import AlertCard from "../cards/AlertCard";

const PaymentandRegistrationForm = ({ navigateToTab }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [showAlertCard, setShowAlertCard] = useState(false);
  const [formData, setFormData] = useState({
    localFirstName: '',
    localLastName: '',
    localPhoneNumber: '',
    selectedPass: '',
    localChannelName: ''
  });
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(true);
   // eslint-disable-next-line no-unused-vars
  const [apiResponse, setApiResponse] = useState("");
   // eslint-disable-next-line no-unused-vars
  const [apiError, setApiError] = useState("");
   // eslint-disable-next-line no-unused-vars
  const [channelCreated, setChannelCreated] = useState(false);
  const [isUploader, setIsUploader] = useState(false);
  const [currentPage, setCurrentPage] = useState('Payment and Registration'); // Add state for current page
  const navigate = useNavigate();

  useEffect(() => {
    // Get stored data
    const storedData = {
      localFirstName: localStorage.getItem('localFirstName') || '',
      localLastName: localStorage.getItem('localLastName') || '',
      localPhoneNumber: localStorage.getItem('localPhoneNumber') || '',
      selectedPass: localStorage.getItem('selectedPass') || '',
      localChannelName: localStorage.getItem('localChannelName') || ''
    };
    setFormData(storedData);
  }, []);

  useEffect(() => {
    if (currentPage === 'Basic Info') {
      // Logic to refresh header or other elements
      console.log('Header refreshed');
    }
  }, [currentPage]); // Depend on currentPage to trigger refresh

  const handleCancelClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmation = (confirm) => {
    setShowConfirmation(false);
    if (confirm) {
      navigateToTab('Basic Info');
      setCurrentPage('Basic Info'); // Set page to Basic Info to trigger refresh
    }
  };

  const handleCompleteClick = () => {
    setShowSummary(true);
  };

  const handleConfirm = async () => {
    setOpen(false);
    setShowSummary(false);

    setShowAlertCard(true);

    try {
      const data1 = await Auth.currentSession();
      const idToken = data1.getIdToken().getJwtToken();
      console.log('ID Token:', idToken);

      const response = await fetch("https://api.kolandium.com/become_uploader", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: formData.localFirstName,
          last_name: formData.localLastName,
          phone_number: formData.localPhoneNumber,
          pass_type: formData.selectedPass,
          channel_name: formData.localChannelName,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok: " + response.statusText);
      }

      const responseText = await response.json();
      console.log('Response Text:', responseText);

      setApiResponse(JSON.stringify(responseText, null, 2));
      setChannelCreated(true);
      setApiError("");
      localStorage.setItem('apiResponse', JSON.stringify(responseText));

      if (responseText.uploader === true) {
        setIsUploader(true);
        localStorage.setItem('isUploader', 'true');
        navigate('/user-profile-display');
      }
    } catch (error) {
      setApiResponse("");
      setApiError(error.message);
      console.error("Error:", error);
    } finally {
      setShowAlertCard(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      {/* Header */}
      <h1 className="text-2xl font-bold my-4">{currentPage}</h1> {/* Header element */}
      
      <div className="text-center rounded-lg w-96">
        <div className="grayscale pointer-events-none">
          <button
            className="text-gray-400 mt-3 bg-white hover:border-b-4 hover:border-green-500 hover:text-black text-black font-bold py-2 px-4 border border-black"
            onClick={() => alert('Payment and Registration')}
          >
            Payment and Registration{' '}
            <span className="text-green-500">✓</span>
          </button>
          <br />
          <div className="mt-4 flex flex-col sm:flex-row justify-center">
            <button
              type="button"
              className="text-gray-400 bg-gray-400 mt-4 sm:mt-0 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center"
              style={{ minWidth: '200px' }}
            >
              <svg className="h-8 w-8 text-white mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
              </svg>
              Credit / Debit card
            </button>
            <button
              type="button"
              className="text-gray-400 bg-gray-400 mt-4 sm:mt-0 ml-0 sm:ml-4 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex items-center"
              style={{ minWidth: '200px' }}
            >
              <svg className="h-8 w-8 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path d="M17.788 5.108A9 9 0 1021 12h-8" />
              </svg>
              Google Pay
            </button>
          </div>

          <h1 className="mt-4">Note for developer</h1>
          <p className="text-gray-400 font-bold text-justify">
            PAYMENT DETAILS HERE BASED ON SELECTED OPTION COMMONLY USED COMPONENTS, NOTHING SPECIAL HERE WILL PROBABLY NEED
            SCROLL ON THE PAGE IF THERE ARE BILLING DETAILS AND ADDRESS THAT NEEDS TO BE INPUT
          </p>
        </div>

        <div className="flex justify-center mt-5 mb-5 gap-5">
          <button
            type="button"
            className="rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 flex items-center"
            onClick={() => navigateToTab('Select Uploader Pass')}
          >
            <span className="ml-1">
              <ArrowLeftIcon className="h-5 w-5 text-white font-bold" aria-hidden="true" />
            </span>
            Previous
          </button>
          <button
            type="button"
            className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 flex items-center"
            onClick={handleCancelClick}
          >
            Cancel
            <span className="ml-1">
              <XMarkIcon className="h-5 w-5 text-white font-bold" aria-hidden="true" />
            </span>
          </button>
          <button
            type="button"
            className={`rounded-md ${isUploader ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600'} px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ${isUploader ? '' : 'hover:bg-green-500'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${isUploader ? '' : 'focus-visible:outline-green-600'} flex items-center justify-center`}
            onClick={() => { if (!isUploader) handleCompleteClick(); }}
            disabled={isUploader}
          >
            {isUploader ? 'You are already an uploader' : 'Complete'}
            <span className="ml-1 text-white-500"> ✓</span>
          </button>
        </div>
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg text-center shadow-lg max-w-sm mx-auto">
            <h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
            <p className="mb-4">Do you really want to go back to the Basic Info page?</p>
            <div className="flex justify-center gap-4">
              <button
                type="button"
                className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                onClick={() => handleConfirmation(true)}
              >
                Yes
              </button>
              <button
                type="button"
                className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={() => handleConfirmation(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showSummary && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg text-center shadow-lg max-w-sm mx-auto">
            <h2 className="text-lg font-semibold mb-4">Summary</h2>
            <p className="mb-4">
              You are about to create a channel with the following details:
            </p>
            <p className="text-left mb-2"><strong>First Name:</strong> {formData.localFirstName}</p>
            <p className="text-left mb-2"><strong>Last Name:</strong> {formData.localLastName}</p>
            <p className="text-left mb-2"><strong>Phone Number:</strong> {formData.localPhoneNumber}</p>
            <p className="text-left mb-2"><strong>Pass Type:</strong> {formData.selectedPass}</p>
            <p className="text-left mb-4"><strong>Channel Name:</strong> {formData.localChannelName}</p>
            <div className="flex justify-center gap-4">
              <button
                type="button"
                className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                onClick={handleConfirm}
              >
                Confirm
              </button>
              <button
                type="button"
                className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={() => setShowSummary(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showAlertCard && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <AlertCard />
        </div>
      )}

    </div>
  );
};

export default PaymentandRegistrationForm;
